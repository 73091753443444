@import '@fortawesome/fontawesome-svg-core/styles.css';
@import 'normalize.css/normalize.css';
@import 'primereact/resources/primereact.min.css';
@import 'primeflex/primeflex.css';
@import 'primeicons/primeicons.css';

@import 'primereact/resources/themes/saga-blue/theme.css';

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica, Arial, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol;
}

.p-divider-horizontal .p-divider-content {
  height: 1px;
  width: 100%;
  background-color: #eee;
}
